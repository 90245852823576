import logo from './logo.svg';
import { lazy, Suspense } from 'react';
import './App.css'; 
import { BrowserRoute, Routes, Route } from 'react-router-dom'
import NavBar from './components/NavBar'
import WaysToBook from './Pages/WaysToBook';
const AboutMe = lazy(() => import('./Pages/AboutMe'));
const Home = lazy(() => import('./Pages/Home'));
const MusicSamples = lazy(() => import('./Pages/MusicSamples'));
const Contact = lazy(() => import('./Pages/Contact'));


const App = () => {
	return (
		<>
			<NavBar />
			<Suspense fallback={<div className="container">Loading...</div>}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/aboutme" element={<AboutMe />} />
					<Route path="/musicsamples" element={<MusicSamples />} />
					<Route path="/waystobook" element={<WaysToBook />} />
          			<Route path="/contact" element={<Contact />} />
				</Routes>
			</Suspense>
		</>
	);
};

export default App;
