import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';

const WaysToBook = () => {
    const navigate = useNavigate();
	return (
		<div className="container">
			<div className="banner-container">
                <p></p>
                <button class = "button-40" onClick={() => navigate("/")}>
                    Home Page
                </button>
                <div className="banner">
                    <h2>Ways to Book</h2>
                    <p> To book me for your event, you can reach out via the contact form under the 'Contact' tab, or you can email me directly: simon.joseph.low@gmail.com.</p>
                    <h3>Packages + Pricing</h3>
                    <ul>$180 - 2-3 hour dinner set</ul>
                    <ul>$220- Cocktail party, or other event with background music</ul>
                    <ul>$200 - Wedding Ceremony</ul>
                     <ul>      + $75 - Cocktail Hour too</ul>       
                    <ul>$300 - Wedding Reception </ul>
                    <ul>$400 - Wedding Ceremony + Cocktail Hour + Reception</ul> 
                    <p> </p>
                </div>
                <Link to="/contact">
                        <div class = "button2">Contact Me Directly</div>
                    </Link>
			</div>
		</div>
	);
};

export default WaysToBook;