import { NavLink } from 'react-router-dom'
//import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import './navbar.css'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
        </div>
        <div className="nav-elements">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/aboutme">About Me</NavLink>
            </li>
            <li>
              <NavLink to="/musicsamples">Music Samples</NavLink>
            </li>
            <li>
              <NavLink to="/waystobook">Ways to Book</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar